




























import { Component, Vue } from 'vue-property-decorator';

import StartButton from '@/components/StartButton.vue';
import {
  dispatchCancelClaim,
  dispatchGetClaimStatus,
  dispatchRunClaim,
} from '@/store/claim/actions';
import { readClaimFinished, readClaimState } from '@/store/claim/getters';
import { commitSetClaimFinished } from '@/store/claim/mutations';
import { commitAddNotification } from '@/store/main/mutations';
import { delay } from '@/utils';

const CLAIM_STATUS_ACTIVE = 1;
const CLAIM_STATUS_INERVAL = 3000; // 3 seconds

@Component({
  components: {
    StartButton,
  },
})
export default class Claim extends Vue {
  public timeOutPointer?: number = undefined;

  get gsState() {
    return readClaimState(this.$store);
  }

  get isFinished() {
    return readClaimFinished(this.$store);
  }

  public async handleSendClaims() {
    await dispatchRunClaim(this.$store);
    this.checkClaimStatus();
  }

  public setFinished(value = true) {
      commitSetClaimFinished(this.$store, value);
  }

  public async checkClaimStatus(cancelled = false) {
    const data = await dispatchGetClaimStatus(this.$store);
    if (data.status === CLAIM_STATUS_ACTIVE) {
      this.setFinished();
      clearInterval(this.timeOutPointer);
      this.timeOutPointer =  setTimeout(() => this.checkClaimStatus(), CLAIM_STATUS_INERVAL);
    } else {
      this.gsState.status = null;
      if (this.isFinished) {
        const statusMsg = this.gsState.message ? this.gsState.message : '';
        const statMsg = data.total ? `подано ${data.done} претензий из ${data.total}.` : '';
        const msg = statMsg + statusMsg;
        const content = cancelled ? 'Претензии: задача отменена' + (msg ? '; ' + msg : '')
          : 'Претензии: ' + msg;
        commitAddNotification(this.$store, { content, color: 'info' });
        this.setFinished(false);
      }
    }
  }

  public async mounted() {
    this.checkClaimStatus();
  }

  public destroyed() {
    clearInterval(this.timeOutPointer);
  }

  public async handleCancelTask() {
    this.gsState.status = null;
    // используем delay, чтобы бэк успел обновить статус в redis
    const res = await Promise.all([dispatchCancelClaim(this.$store), delay(500)]);
    if (res) {
      clearInterval(this.timeOutPointer);
      this.checkClaimStatus(true);
    }
  }
}
